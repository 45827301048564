<template>
	<v-container
		class="fill-height"
		fluid>
		<v-row
			align="center"
			justify="center">
			<v-col
				cols="11"
				sm="9"
				md="8">
				<v-card color="transparent"
					elevation="0">
					<h1 class="display-1 font-weight-black font-italic secondary--text my-10">Вхід</h1>
					<validation-observer ref="observer">
						<v-form @submit.prevent="submit">
							<v-row>
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<validation-provider
										name="email"
										rules="required|email"
										v-slot="{ errors }">
										<v-text-field
											v-model="user.email"
											type="email"
											label="email"
											:error-messages="errors"/>
									</validation-provider>
								</v-col>
								
								<v-col
									cols="12"
									md="6"
									class="py-0">
									<validation-provider
										name="пароль"
										rules="required|min:8"
										v-slot="{ errors }">
										<v-text-field
											v-model="user.password"
											type="password"
											label="пароль"
											:error-messages="errors"/>
									</validation-provider>
								</v-col>
								
							</v-row>

							<v-row class="mb-10 align-center">
								<v-col
									cols="12">
									<v-btn
										type="submit"
										color="btnBg"
										class="btnTxt--text"
										v-ripple="{ class: 'success--text' }">
										Увійти
									</v-btn>
								</v-col>
							</v-row>

						</v-form>
					</validation-observer>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data: () => ({
		user: {
			email: '',
			password: '',
		},
	}),
	methods: {
		...mapActions('auth', [
			'login',
		]),
		// eslint-disable-next-line complexity
		async submit() {
			try {
				const valid = await this.$refs.observer.validate()
				if (valid) {
					try {
						const success = await this.login(this.user)
						if (success) this.$router.push({ name: 'home' })
					} catch (error) {
						// eslint-disable-next-line no-console
						console.error(error)
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error)
			}
		},
	},
}
</script>